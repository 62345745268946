<script setup lang="ts">
import { toHead } from 'vue-datocms';

import { ContentModules } from '~/components/content-modules/ContentModules';
import { HeaderModules } from '~/components/header-modules/HeaderModules';
import { useContainerIntersections } from '~/utils/useContainerIntersections';

const i18n = useI18n();

const route = useRoute();

if (route.name === 'index___de___default') {
  const locales = ['de', 'fr', 'it'];
  let browserLocale = i18n?.getBrowserLocale() || 'de';

  if (!locales.includes(browserLocale)) {
    browserLocale = 'de';
  }

  // await navigateTo({ name: `index___${i18n?.getBrowserLocale() || 'de'}` });
}

const { data, error } = await useAsyncGql({
  operation: 'GetPageBySlug',
  variables: { slug: 'home', locale: i18n.localeProperties.value.siteLocale }
});

const page = data.value.page;

if (!page || error.value) {
  showError({
    statusCode: 404
  });
}

useHead(toHead(page?.seo ?? {}));

const containerElements = ref<HTMLElement[]>([]);

useContainerIntersections(containerElements);
</script>

<template>
  <main class="layout-default">
    <div class="container" ref="containerElements" v-for="(content, index) in page.contentCards">
      <div class="wrapper" :class="`-background-${content.background}`">
        <component
          v-if="index === 0 && page.headerModule"
          :is="HeaderModules[page.headerModule.__typename]"
          :data="page.headerModule"
        >
        </component>
        <component
          v-for="module in content.cards"
          :key="module.id"
          :is="ContentModules[module.__typename]"
          :data="module"
        >
        </component>
      </div>
    </div>
  </main>
</template>

<style scoped lang="scss">
.layout-default {
}
</style>
